import { MembersOnlyChip } from "@components/controls/MembersOnlyChip"
import { ChipList } from "@components/layout/ChipList"
import { simpleDate } from "@utils/dates"
import { Link } from "eddev/routing"
import { ReactNode } from "react"
import { tv } from "tailwind-variants"
import { ChipsScalar } from "../../types.graphql"

type Props = {
  mode: "centered" | "editorial"
  title: ReactNode
  subtitle?: ReactNode
  chips?: ChipsScalar
  // collection?: CollectionChipFragment | null
  // tags?: ArticleCategoryChipFragment[]
  // branches?: BranchChipFragment[]
  date?: string
  author?: { uri: string | null; name: string | null }
  membersOnly?: boolean
}

const variants = tv({
  slots: {
    grid: "md:mt-6 grid-auto mb-3",
    col: "col-span-12",
    title: "type-title-l-medium my-0 text-pretty md:text-balance",
    subtitle: "type-title-l-roman pt-1 my-0 text-pretty md:text-balance",
    metaCol: "col-span-12 border-t border-black mt-5 pt-2",
    metaItem: "type-body-s-roman text-grey",
    tags: "pt-2",
  },
  variants: {
    mode: {
      editorial: {
        col: "md:col-span-10",
        metaCol: "md:grid-auto",
        metaItem: "md:col-span-3 md:last:text-left",
      },
      centered: {
        col: "md:col-start-3 md:col-end-11 lg:col-start-4 lg:col-end-10",
        title: "md:text-center",
        subtitle: "md:text-center",
        tags: "md:justify-center",
        metaCol: "md:border-t-0 md:mt-4 md:col-start-4 md:col-span-6 md:flex  md:justify-between",
        metaItem: "md:only:justify-self-center",
      },
    },
  },
})

export function ArticleHeader(props: Props) {
  const styles = variants({ mode: props.mode })

  return (
    <div className={styles.grid()}>
      <div className={styles.col()} data-transition="children/fade">
        <h1 className={styles.title()}>{props.title}</h1>
        <p className={styles.subtitle()}>{props.subtitle}</p>
        {(props?.chips?.length! > 0 || props.membersOnly) && <ChipList className={styles.tags()} chips={props.chips!} />}
      </div>
      <div className={styles.metaCol()} data-transition="fade">
        {!!props.date && <div className={styles.metaItem()}>{simpleDate(props.date)}</div>}
        {!!props.author?.name && (
          <div className={styles.metaItem()}>Words by {props.author.uri! ? <Link href={props.author.uri!}>{props.author.name}</Link> : props.author.name}</div>
        )}
      </div>
    </div>
  )
}
